define('workshop-ember-1-11/snippets', ['exports'], function (exports) {

  'use strict';

  exports['default'] = { "access-service.js": "test('has storage', function (assert) {\r\n  var component = this.subject();\r\n\r\n  // storage property is available on the component\r\n  assert.ok(component.storage, \"storage injection is available on the component\");\r\n\r\n  // property is instance of an InjectedProperty\r\n  // @see https://github.com/emberjs/ember.js/blob/master/packages/ember-metal/lib/injected_property.js#L18\r\n  assert.ok(!(component.storage instanceof LocalStorageService), \"injected property is not instance of LocalStorageService\");\r\n\r\n  // get returns instance of the service looked up in the container\r\n  assert.ok(component.get('storage') instanceof LocalStorageService, \"component.get returns instance of injected service\");\r\n});\r\n",
    "component-helper-block-template.hbs": "{{#component componentName}}\r\n  YOUR CODE GOES HERE\r\n{{/component}}\r\n",
    "component-helper-demo.hbs": "{{#each model as |component|}}\r\n  {{#choose-option original=component.name options=options as |componentName|}}\r\n    {{component componentName original=component.name}}\r\n  {{/choose-option}}\r\n{{/each}}\r\n",
    "component-helper-route.js": "export default Ember.Route.extend({\r\n  model() {\r\n    return [ { name: 'red-foo' }, { name: 'blue-foo' }, { name: 'yellow-foo' } ];\r\n  }\r\n});\r",
    "component-helper-with-parameters.hbs": "{{component componentName tagName=\"li\"}}\r\n",
    "component-helper.hbs": "{{#each model as |component|}}\r\n  {{component componentName}}\r\n{{/each}}\r\n",
    "create-service.sh": "ember generate service local-storage\r\n",
    "each-with-index.hbs": "<ul>\r\n  {{#each model as |text index|}}\r\n      <li>{{text}} at {{index}}</li>\r\n  {{/each}}\r\n</ul>\r\n",
    "inline-if-property.hbs": "<button style=\"background-color: {{backgroundColor}}; color: {{if isDark 'white' inverseColor}};\" {{action 'changeColor'}}>Change Color</button>\r\n",
    "inline-if-strings.hbs": "<li>Is Dark: {{if isDark 'Yes' 'No'}}</li>\r\n",
    "new-shiny-controller.js": "export default Ember.Controller.extend({\r\n  /**\r\n   * Explicitly reference properties on model object\r\n   */\r\n  fullName: Ember.computed('model.firstName', 'model.lastName', function(){\r\n    var firstName = this.get('model.firstName');\r\n    var lastName = this.get('model.lastName');\r\n    return `${firstName} ${lastName}`;\r\n  })\r\n});\r",
    "no-bind-attr-svg.hbs": "<svg class=\"circle\" height={{size}} width={{size}} {{action 'changeColor'}}>\r\n    <circle cx=\"{{radius}}\" cy=\"{{radius}}\" r=\"{{radius}}\" fill={{color}} />\r\n</svg>\r\n",
    "old-school-controller.js": "export default Ember.ObjectController.extend({\r\n  /**\r\n   * ObjectController is a controller that implements ObjectProxy\r\n   * ObjectProxy proxies properties on the controller to object on content property\r\n   *\r\n   *  this.get('firstName') -> this.get('content.firstName')\r\n   *  this.set('firstName', value) -> this.set('content.firstName', value)\r\n   */\r\n  fullName: Ember.computed('firstName', 'lastName', function(){\r\n    var firstName = this.get('firstName');\r\n    var lastName = this.get('lastName');\r\n    return `${firstName} ${lastName}`;\r\n  })\r\n});\r",
    "polymer.html": "<polymer-element name=\"svg-pulsing-circle\" attributes=\"color size radius duration startscale endscale\">\r\n  <template>\r\n    <style>\r\n      .circle {\r\n        width:100px;\r\n        height:100px;\r\n      }\r\n    </style>\r\n    <svg class=\"circle\" height={{size}} width={{size}}>\r\n      <circle cx=\"{{radius}}\" cy=\"{{radius}}\" r=\"{{radius}}\" fill={{color}} />\r\n    </svg>\r\n  </template>\r\n  <script>\r\n    Polymer({\r\n      color: 'blue',\r\n      duration: 2200,\r\n      size: 100,\r\n      radius: 25,\r\n      ready: function() {\r\n        this.color = 'yellow';\r\n        var circle = this.shadowRoot.querySelector('.circle');\r\n        circle.animate([\r\n            {opacity: \"1.0\"},\r\n            {opacity: \"0\"},\r\n            {opacity: \"1.0\"}\r\n          ],\r\n          {\r\n            duration: this.duration,\r\n            easing: 'cubic-bezier(.22,.67,.52,.92)',\r\n            fill: 'forwards',\r\n            iterations: Infinity\r\n          });\r\n      }\r\n    });\r\n  </script>\r\n</polymer-element>\r\n",
    "singleton-service.js": "moduleFor('route:inject-services', {\r\n  // Specify the other units that are required for this test.\r\n  needs: ['controller:inject-services', 'service:local-storage']\r\n});\r\n\r\ntest('storage service is a singleton', function(assert) {\r\n  var route = this.subject();\r\n\r\n  var controller = route.controllerFor('inject-services');\r\n\r\n  assert.equal(route.get('storage'), controller.get('storage'), \"both route and controller storage have same object\");\r\n});\r\n",
    "stored-input-component.js": "import Ember from 'ember';\r\n\r\nexport default Ember.Component.extend({\r\n  /**\r\n   * Inject local-storage on storage property\r\n   */\r\n  storage: Ember.inject.service('local-storage'),\r\n\r",
    "use-stored-input.hbs": "{{stored-input key=\"foo\"}}\r\n",
    "no-bind-attr-polymer.hbs": "<svg-pulsing-circle color=\"{{color}}\" size={{size}} radius={{radius}} {{action 'changeColor'}}></svg-pulsing-circle>\r\n",
    "no-bind-attr-style.hbs": "<div class=\"css-circle\" {{action 'changeColor'}}></div>\r\n\r\n<style type=\"text/css\">\r\n    .css-circle {\r\n        border-radius: 50%;\r\n        height: {{half}}px;\r\n        width: {{half}}px;\r\n        background-color: {{color}};\r\n    }\r\n</style>\r\n" };

});