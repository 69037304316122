define('workshop-ember-1-11/no-bind-attr/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.0-beta.5",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h2");
        var el2 = dom.createTextNode("No Bind Attr");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    \n     ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("google-chart");
        dom.setAttribute(el2,"type","pie");
        dom.setAttribute(el2,"options","{\"title\": \"Distribution of days in 2015Q1\"}");
        dom.setAttribute(el2,"cols","[{\"label\":\"Month\", \"type\":\"string\"}, {\"label\":\"Days\", \"type\":\"number\"}]");
        dom.setAttribute(el2,"rows","[[\"Apr\", 30],[\"May\", 31],[\"June\", 30]]");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        var el3 = dom.createTextNode("With Style");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","css-circle");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("style");
        dom.setAttribute(el2,"type","text/css");
        var el3 = dom.createTextNode("\n        .css-circle {\n            border-radius: 50%;\n            height: ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("px;\n            width: ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("px;\n            background-color: ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(";\n        }\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        var el3 = dom.createTextNode("With SVG");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2,"class","circle");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("circle");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        var el3 = dom.createTextNode("With Polymer");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("svg-pulsing-circle");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, inline = hooks.inline, element = hooks.element, content = hooks.content, attribute = hooks.attribute, concat = hooks.concat;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [4]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(element0, [7]);
        var element3 = dom.childAt(fragment, [6]);
        var element4 = dom.childAt(element3, [5]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(fragment, [8]);
        var element7 = dom.childAt(element6, [5]);
        var morph0 = dom.createMorphAt(dom.childAt(fragment, [2, 1]),1,1);
        var morph1 = dom.createMorphAt(element0,3,3);
        var morph2 = dom.createMorphAt(element2,1,1);
        var morph3 = dom.createMorphAt(element2,3,3);
        var morph4 = dom.createMorphAt(element2,5,5);
        var morph5 = dom.createMorphAt(element3,3,3);
        var attrMorph0 = dom.createAttrMorph(element4, 'height');
        var attrMorph1 = dom.createAttrMorph(element4, 'width');
        var attrMorph2 = dom.createAttrMorph(element5, 'cx');
        var attrMorph3 = dom.createAttrMorph(element5, 'cy');
        var attrMorph4 = dom.createAttrMorph(element5, 'r');
        var attrMorph5 = dom.createAttrMorph(element5, 'fill');
        var morph6 = dom.createMorphAt(element6,3,3);
        var attrMorph6 = dom.createAttrMorph(element7, 'color');
        var attrMorph7 = dom.createAttrMorph(element7, 'size');
        var attrMorph8 = dom.createAttrMorph(element7, 'radius');
        var morph7 = dom.createMorphAt(element6,7,7);
        inline(env, morph0, context, "input", [], {"type": "range", "value": get(env, context, "size"), "min": 50, "max": 200});
        inline(env, morph1, context, "code-snippet", [], {"name": "no-bind-attr-style.hbs"});
        element(env, element1, context, "action", ["changeColor"], {});
        content(env, morph2, context, "half");
        content(env, morph3, context, "half");
        content(env, morph4, context, "color");
        inline(env, morph5, context, "code-snippet", [], {"name": "no-bind-attr-svg.hbs"});
        attribute(env, attrMorph0, element4, "height", get(env, context, "half"));
        attribute(env, attrMorph1, element4, "width", get(env, context, "half"));
        element(env, element4, context, "action", ["changeColor"], {});
        attribute(env, attrMorph2, element5, "cx", concat(env, [get(env, context, "radius")]));
        attribute(env, attrMorph3, element5, "cy", concat(env, [get(env, context, "radius")]));
        attribute(env, attrMorph4, element5, "r", concat(env, [get(env, context, "radius")]));
        attribute(env, attrMorph5, element5, "fill", get(env, context, "color"));
        inline(env, morph6, context, "code-snippet", [], {"name": "no-bind-attr-polymer.hbs"});
        attribute(env, attrMorph6, element7, "color", concat(env, [get(env, context, "color")]));
        attribute(env, attrMorph7, element7, "size", get(env, context, "half"));
        attribute(env, attrMorph8, element7, "radius", get(env, context, "radius"));
        element(env, element7, context, "action", ["changeColor"], {});
        inline(env, morph7, context, "code-snippet", [], {"name": "polymer.html"});
        return fragment;
      }
    };
  }()));

});