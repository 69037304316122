define('workshop-ember-1-11/object-controller/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return {
        firstName: "Tom",
        lastName: "Huda"
      };
    }
  });

});