define('workshop-ember-1-11/object-controller/old-school/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ObjectController.extend({
    /**
     * ObjectController is a controller that implements ObjectProxy
     * ObjectProxy proxies properties on the controller to object on content property
     *
     *  this.get('firstName') -> this.get('content.firstName')
     *  this.set('firstName', value) -> this.set('content.firstName', value)
     */
    fullName: Ember['default'].computed("firstName", "lastName", function () {
      var firstName = this.get("firstName");
      var lastName = this.get("lastName");
      return "" + firstName + " " + lastName;
    })
  });
  // END-SNIPPET

});