define('workshop-ember-1-11/tests/unit/inject-services/route-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleFor("route:inject-services", {
    // Specify the other units that are required for this test.
    needs: ["controller:inject-services", "service:local-storage"]
  });

  ember_qunit.test("storage service is a singleton", function (assert) {
    var route = this.subject();

    var controller = route.controllerFor("inject-services");

    assert.equal(route.get("storage"), controller.get("storage"), "both route and controller storage have same object");
  });

});