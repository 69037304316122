define('workshop-ember-1-11/component-helper/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return [{ name: "red-foo" }, { name: "blue-foo" }, { name: "yellow-foo" }];
    }
  });
  // END-SNIPPET

});