define('workshop-ember-1-11/object-controller/new-shiny/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    /**
     * Explicitly reference properties on model object
     */
    fullName: Ember['default'].computed("model.firstName", "model.lastName", function () {
      var firstName = this.get("model.firstName");
      var lastName = this.get("model.lastName");
      return "" + firstName + " " + lastName;
    })
  });
  // END-SNIPPET

});