define('workshop-ember-1-11/inline-if/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    backgroundColor: randomColor(),
    actions: {
      changeColor: function changeColor() {
        this.set("backgroundColor", randomColor());
      }
    },
    isDark: Ember['default'].computed("backgroundColor", function () {
      return isDark(this.get("backgroundColor"));
    }),
    inverseColor: Ember['default'].computed("backgroundColor", function () {
      return inverseColor(this.get("backgroundColor"));
    })
  });

  /**
   * @source http://www.paulirish.com/2009/random-hex-color-code-snippets/
   * @returns {string}
   */
  function randomColor() {
    return "#" + (function co(_x) {
      var _again = true;

      _function: while (_again) {
        _again = false;
        var lor = _x;
        if ((lor += [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "a", "b", "c", "d", "e", "f"][Math.floor(Math.random() * 16)]) && lor.length === 6) {
          return lor;
        } else {
          _x = lor;
          _again = true;
          continue _function;
        }
      }
    })("");
  }

  /**
   * @source http://stackoverflow.com/a/12043228
   * @param color {string}
   * @returns {number}
   */
  function isDark(color) {
    var c = color.substring(1); // strip #
    return parseInt(c, 16) > 16777215 / 2;
  }

  /**
   * @source http://jsperf.com/inverse-hex-colours
   * @param color
   * @returns {*}
   */
  function inverseColor(color) {
    function inverse(hex) {
      if (hex.length !== 7 || hex.indexOf("#") !== 0) {
        return null;
      }
      var r = (255 - parseInt(hex.substring(1, 3), 16)).toString(16);
      var g = (255 - parseInt(hex.substring(3, 5), 16)).toString(16);
      var b = (255 - parseInt(hex.substring(5, 7), 16)).toString(16);
      return "#" + pad(r) + pad(g) + pad(b);
    }

    function pad(num) {
      if (num.length < 2) {
        return "0" + num;
      } else {
        return num;
      }
    }
    return inverse(color);
  }

});