define('workshop-ember-1-11/router', ['exports', 'ember', 'workshop-ember-1-11/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.map(function () {
    this.route("inject-services");
    this.route("inline-if");
    this.route("no-bind-attr");
    this.route("each-with-index");
    this.route("component-helper");
    this.route("object-controller", function () {
      this.route("old-school");
      this.route("new-shiny");
    });
  });

  exports['default'] = Router;

});