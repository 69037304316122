define('workshop-ember-1-11/no-bind-attr/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    size: 100,
    color: randomColor(),
    radius: Ember['default'].computed("size", function () {
      return this.get("size") / 4;
    }),
    half: Ember['default'].computed("size", function () {
      return this.get("size") / 2;
    }),
    actions: {
      changeColor: function changeColor() {
        this.set("color", randomColor());
      }
    }
  });

  /**
   * @source http://www.paulirish.com/2009/random-hex-color-code-snippets/
   * @returns {string}
   */
  function randomColor() {
    return "#" + (function co(_x) {
      var _again = true;

      _function: while (_again) {
        _again = false;
        var lor = _x;
        if ((lor += [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "a", "b", "c", "d", "e", "f"][Math.floor(Math.random() * 16)]) && lor.length === 6) {
          return lor;
        } else {
          _x = lor;
          _again = true;
          continue _function;
        }
      }
    })("");
  }

});