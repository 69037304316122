define('workshop-ember-1-11/choose-option/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    // array or options to show
    options: [],
    // original option
    original: null,
    // current option
    current: Ember['default'].computed.oneWay("original"),
    actions: {
      setCurrent: function setCurrent(option) {
        this.set("current", option);
      }
    }
  });

});