define('workshop-ember-1-11/each-with-index/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    actions: {
      shuffleModel: function shuffleModel() {
        var model = this.get("model");
        model.setObjects(shuffle(model));
      }
    },
    list: Ember['default'].computed("model.@each", function () {
      return this.get("model").join(", ");
    })
  });

  /**
   * @source http://stackoverflow.com/a/2450976
   * @param array
   * @returns {*}
   */
  function shuffle(array) {
    var currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

});