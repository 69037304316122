define('workshop-ember-1-11/each-with-index/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return ["foo", "bar", "baz", "woo", "goo", "daa", "brr"];
    }
  });

});