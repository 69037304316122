define('workshop-ember-1-11/services/local-storage', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend(Ember['default'].Evented, {
    init: function init() {
      /**
       * Problem: 'storage' event only fires when value change happens on a different window
       * Solution: "fix" this inside of our service
       */
      window.addEventListener("storage", Ember['default'].run.bind(this, this.callback));
    },
    setItem: function setItem(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
      Ember['default'].run(this, this.callback, { key: key, newValue: value });
    },
    getItem: function getItem(key) {
      return JSON.parse(localStorage.getItem(key));
    },
    clear: function clear() {
      localStorage.clear();
    },
    callback: function callback(event) {
      // notify listeners of change
      this.trigger("change", event.key, event.newValue);
    },
    unknownProperty: function unknownProperty(key) {
      return this.getItem(key);
    },
    setUnknownProperty: function setUnknownProperty(key, value) {
      this.setItem(key, value);
      this.notifyPropertyChange(key);
      return value;
    }
  });

});