define('workshop-ember-1-11/stored-input/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  // BEGIN-SNIPPET stored-input-component
  exports['default'] = Ember['default'].Component.extend({
    /**
     * Inject local-storage on storage property
     */
    storage: Ember['default'].inject.service("local-storage"),

    // END-SNIPPET
    tagName: "input",
    type: "text",
    attributeBindings: ["value", "type"],
    /**
     * Key where value is stored
     */
    key: null,
    value: "",
    focusOut: function focusOut() {
      // on focusOut store value
      var storage = this.get("storage");
      var key = this.get("key");
      if (key) {
        storage.setItem(key, this.get("value"));
      }
    },
    change: function change() {
      // when element value changes update component value property
      this.set("value", this.element.value);
    },
    setValueFromStorage: (function () {
      // didInsertElement set component value to value from storage
      var storage = this.get("storage");
      var key = this.get("key");
      if (key) {
        this.set("value", storage.getItem(key));
      }
    }).on("didInsertElement")
  });

});