define('workshop-ember-1-11/tests/unit/stored-input/component-test', ['ember', 'ember-qunit', 'workshop-ember-1-11/services/local-storage'], function (Ember, ember_qunit, LocalStorageService) {

  'use strict';

  ember_qunit.moduleForComponent("stored-input", {
    // specify the other units that are required for this test
    needs: ["service:local-storage"],
    teardown: function teardown() {
      window.localStorage.clear();
    }
  });

  ember_qunit.test("it renders", function (assert) {
    assert.expect(2);

    // creates the component instance
    var component = this.subject();
    assert.equal(component._state, "preRender");

    // renders the component to the page
    this.render();
    assert.equal(component._state, "inDOM");
  });

  // BEGIN-SNIPPET access-service
  ember_qunit.test("has storage", function (assert) {
    var component = this.subject();

    // storage property is available on the component
    assert.ok(component.storage, "storage injection is available on the component");

    // property is instance of an InjectedProperty
    // @see https://github.com/emberjs/ember.js/blob/master/packages/ember-metal/lib/injected_property.js#L18
    assert.ok(!(component.storage instanceof LocalStorageService['default']), "injected property is not instance of LocalStorageService");

    // get returns instance of the service looked up in the container
    assert.ok(component.get("storage") instanceof LocalStorageService['default'], "component.get returns instance of injected service");
  });
  // END-SNIPPET

  ember_qunit.test("input field reads value from stored key", function (assert) {
    window.localStorage.setItem("foo", "\"bar\"");
    var component = this.subject({
      key: "foo"
    });

    this.render();
    assert.equal(component.get("value"), "bar", "bar was retrieved from localStorage");
  });

  ember_qunit.test("input field sets localStorage on focus out", function (assert) {
    var component = this.subject({
      key: "foo"
    });

    this.render();
    this.$().focus();
    Ember['default'].run(function () {
      component.set("value", "baz");
    });
    this.$().blur();

    assert.equal(window.localStorage.getItem("foo"), "\"baz\"", "the value was updated on focus out");
  });

});